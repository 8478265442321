import React, { Component } from 'react';

import moment from 'moment';

import { makeStyles } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

class AppointmentDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      appointmentRequest: props.appointmentRequest 
    };
  }

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Appointment Information
      </Typography>
      <Typography variant="body1" gutterBottom>
      You'll receive a confirmation by email with all the details. 
      </Typography>
      </React.Fragment>
    )
  }
}

export default AppointmentDetails;
